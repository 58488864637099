import * as React from 'react'
import IndexLayout from '../layouts'

export default function Impressum() {
  return (
    <IndexLayout>
      <h1 className="sectionTitle">Impressum</h1>
      <h2>Allgemeine Erklärung</h2>
      <p>
        Trotz sorgfältiger Prüfung kann ich keine Haftung für die Inhalte externer Links übernehmen. Für den Inhalt dieser Seiten sind
        ausschließlich deren Betreiber verantwortlich.{' '}
      </p>
      <p>
        Ich, Brigitte Fink bin keine Ärztin, Psychologin, Psychiaterin. Dementsprechend stellt die Lebensberatung weder einen Ersatz für
        ärztliche Untersuchung und der daraus folgenden Diagnosen, noch für psychologische oder psychotherapeutische Behandlungen dar.{' '}
      </p>
      <p>
        Sämtliche Aussagen und Ratschläge sind keine Diagnosen. Der/die KlientIn wird darüber informiert, dass er/sie sich für die
        Erstellung einer Diagnose und Therapie an seinen/ihren Arzt und/oder Psychologen zu wenden hat .
      </p>
    </IndexLayout>
  )
}
